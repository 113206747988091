export const trainingPhrasesCollection: Map<string, string[]> = new Map();

const defaultWelcome_EN = [
  'just going to say hi',
  'heya',
  'hello hi',
  'howdy',
  'hey there',
  'hi there',
  'greetings',
  'hey',
  'long time no see',
  'hello',
  "lovely day isn't it",
  'I greet you',
  'hello again',
  'hi',
  'hello there',
  'a good day',
];

const defaultWelcome_FR = [
  'Salutations',
  'Rebonjour',
  'bonjour à toi',
  're bonjour',
  'allo',
  'coucou',
  'hey',
  'hé',
  'resalut',
  'salut',
  're salut',
  'salut à tous',
  'bonjour',
];

const defaultWelcome_BR = [
  'há quanto tempo',
  'olá',
  'oi',
  'opa',
  'fala aí',
  'fala',
  'saudações',
  'oi tudo bem',
  'e aí',
  'eae',
];

const yes_EN = [
  'yes',
  'okay I will',
  'why not',
  "yes that's alright",
  'yes I do',
  'exactly',
  'of course',
  "yep that's ok",
  'okay',
  'ok',
  'for sure',
  'sg',
  "yes that't ok",
  'I agree',
  'yes you can do it',
  "I don't mind",
  'that one works',
  'that works',
  'sure why not',
  'perfect',
  "yep that's right",
  'I think so',
  'yes I agree',
  'sure',
  'sounds correct',
  'sounds good',
  "that's correct",
  'go ahead',
  'do it',
  "it's fine",
  'yeah',
  'yes please',
  "it's okay",
  'alright why not',
  'alright',
  'right',
  'it looks perfect',
  'yes I can',
  'yup',
  'yep',
  'confirm',
  'absolutely',
];

const yes_FR = [
  'fais-le',
  "c'est correct",
  "d'accord",
  'bien sûr',
  'exactement',
  'confirmer',
  "je suis d'accord",
  'oui',
  'ça me va',
];

const yes_BR = [
  'faça isso',
  'confirmar',
  'sim',
  'isso mesmo',
  'concordo',
  'parece bom',
  "claro",
  'exatamente',
  'com certeza',
];

const no_EN = [
  'thanks but no',
  'no way',
  'why not',
  'no',
  "no no don't",
  'na',
  "no it isn't",
  "don't",
  "nah I'm good",
  'no I cannot',
  "I can't",
  'Nothing',
  "no that's okay",
  'no not really',
  'nope not really',
  'nope',
  'thanks but not this time',
  "I don't think so",
  'I disagree',
  'no maybe next time',
  'not this time',
  "no don't",
  'no we are good',
  "don't do it",
  "no that's be all",
  'not right now',
  'nothing else thanks',
  'no thanks',
  "no that's ok",
  "I don't want that",
  'definitely not',
  'nothing else',
  'not',
  'not at all',
  'no never',
  'never',
  'no way no',
  'not really',
  'nah',
  "I don't",
  "I don't want",
  'not today',
  'not interested',
  "no that's fine thank you",
  "I'm not",
];

const no_FR = [
  'je ne crois pas',
  "ça ne m'intéresse pas",
  'ne le fais pas',
  'pas vraiment',
  'sûrement pas',
  'non merci',
  "je n'en veux pas",
  'je ne suis',
];

const no_BR = [
  'discordo',
  'definitivamente não',
  'não obrigado',
  'acho que não',
  'não',
  'não tenho interesse',
  'não quero isso',
  'não faça isso',
  'não mesmo',
];

trainingPhrasesCollection.set('yes-en_CA', yes_EN);
trainingPhrasesCollection.set('yes-fr_CA', yes_FR);
trainingPhrasesCollection.set('yes-pt_BR', yes_BR);
trainingPhrasesCollection.set('no-en_CA', no_EN);
trainingPhrasesCollection.set('no-fr_CA', no_FR);
trainingPhrasesCollection.set('no-pt_BR', no_BR);
trainingPhrasesCollection.set('defaultWelcome-en_CA', defaultWelcome_EN);
trainingPhrasesCollection.set('defaultWelcome-fr_CA', defaultWelcome_FR);
trainingPhrasesCollection.set('defaultWelcome-pt_BR', defaultWelcome_BR);
